<template>
 <div class="content_box">
    <div class="search_bar">
      <baseSearch :formItemList="searchItemList" @search="search" :emitSearch="true"></baseSearch>
    </div>
    <div class="main-box">
      <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent" ref="mainTable">
        <template v-slot:operation="actionBtn">
          <el-button type="warning" plain size="small" @click="verifyItem(actionBtn.data)" v-if="actionBtn.data.applyState == 0">审核</el-button>
          <el-button type="primary" plain size="small" @click="verifyItem(actionBtn.data)" v-if="actionBtn.data.applyState != 0">查看</el-button>
        </template>
      </mainTable>
    </div>
  </div>
  <el-dialog v-model="showVerify" title="审核提现账号切换申请" width="680" style="padding-bottom:20px">
    <p>运营商：<span style="color: #f1a325;font-weight: bold;">{{verifyInfo.operatorName}}</span></p>
    <el-table :data="verifyTD" border size="small">
      <el-table-column prop="info" label="" width="80" class-name="table_column_info"/>
      <el-table-column prop="oldAccountDetail" label="当前提现账号" width="300"/>
      <el-table-column prop="newAccountDetail" label="更换为" class-name="table_column_newAccountDetail"/>
    </el-table>
    <p>提现账户变更通知函： <el-button type="success" size="small"  @click="showPreview=true">预览</el-button></p>
    <div style="text-align: right;padding-bottom: 10px;" v-if="verifyInfo.applyState==0">
      <el-button type="primary" @click="passItem" :disabled="canEdit">通过</el-button>
      <el-button type="danger" @click="nopassItem">不通过</el-button>
    </div>
  </el-dialog>
  <el-image-viewer v-if="showPreview" @close="showPreview=false" :initial-index="0" :url-list="[verifyInfo.imgUrl]" />
  <rejectPopup v-if="disagreeDialog" :rejectInfo="rejectInfo" :iscanEdit="canEdit" @closedialog="disagreeDialog=false" @submitform="submitForm"></rejectPopup>
</template>
<style>
  .table_column_info{
    background-color: #F5F5F5;
  }
  .table_column_newAccountDetail{
    color: red;
  }
</style>

<script>
  export default {
    data() {
      return {
        baseApiUrl: '/finance',
        STATUS: [
            { Code: '0', Name: "未审核" },
            { Code: '1', Name: "通过" },
            { Code: '2', Name: "不通过" },
            { Code: '3', Name: "作废" }
        ],
        showPreview:false,
        searchData: {},
        selectOptions:[],
        mainData: {
          DataList: [],
          Total: -1,
          labelList: [
            { label: "操作", prop: "operation", slot_name: 'operation', type: 'slot', width: "70", fixed: 'left' },
            { label: "运营商名称", width: 200, prop: "operatorName" },
            { label: "申请时间", width: 140, prop: "createdTime" },
            { label: "状态", width: 100, prop: "applyStateName" },
            { label: "审核人", width: 100, prop: "auditedBy" },
            { label: "审核时间", width: 140, prop: "auditedTime" },
            { label: "拒绝原因", minWidth: 200, prop: "rejectRemark" },
          ]
        },
        pageinfo: {
          pg_idx: 1,
          pg_size: 15
        },
        // showVerify_dialog
        showVerify:false,
        verifyInfo:{},
        verifyTD:[],
        // disagree_dialog
        disagreeDialog:false,
        rejectInfo:{title:'未通过的原因！',label:'理由',value:'remark'},
        canEdit:false
      }
    },
    mounted() {
      this.getOperatorInfo()
    },
    computed:{
      searchItemList(){
        return [
          { label: "状态：", type: "select", value: "applyState", selectOptions: this.STATUS, defaultValue: '0', multiple: false ,keyValue:['Code','Name']},
          { label: "运营商：", type: "select", value: "operatorCode", selectOptions: this.selectOptions,clearable:true, multiple: false ,keyValue:['Code','Name']},
        ]
      }
    },
    methods: {
      getOperatorInfo(){
        this.$store.dispatch('cache/loadCacheData',this.CacheKeys.OperatorInfo).then((res) => {
          this.selectOptions = res;
        });
      },
      search(postData) {
        this.pageinfo.pg_idx = 1;
        this.searchData = postData || this.searchData;
        this.getListData();
      },
      getListData() {
        this.$http.post(this.baseApiUrl + '/GetWithdrawBankAccountTransferApplyList', { ...this.searchData, ...this.pageinfo }).then((res)=>{
          this.mainData.DataList = res.data.rows;
          this.mainData.Total = res.data.total || -1;
        });
      },
      getPageCurrent(val) {
        this.pageinfo.pg_idx = val;
        this.getListData();
      },
      verifyItem(row,type){
        this.$http.post(this.baseApiUrl + '/GetWithdrawBankAccountTransferApplyDetil', {id:row.id}).then((res)=>{
          this.verifyInfo = res.data
          this.verifyInfo.applyState = row.applyState
          let o = res.data.oldAccountDetail
          let n = res.data.newAccountDetail
          this.verifyTD = [
            {
              info: '户名',
              oldAccountDetail: o.accountNameFull,
              newAccountDetail: n.accountNameFull,
            },
            {
              info: '开户银行',
              oldAccountDetail: o.branchName,
              newAccountDetail: n.branchName,
            },
            {
              info: '银行账号',
              oldAccountDetail: o.cardNumber,
              newAccountDetail: n.cardNumber,
            },
          ]
          this.showVerify = true
        });
      },
      passItem(){
        this.submitResult(1)
      },
      nopassItem(){
        this.showVerify = false
        this.disagreeDialog = true
      },
      submitForm(value){
        this.submitResult(2,value)
      },
      submitResult(type,rejectObj){
        let params = {
            id: this.verifyInfo.id,
            rejectRemark: type == 1 ? '' : rejectObj.remark,
            applyState:type
        }
        this.canEdit = true
        this.$http.post(this.baseApiUrl + '/WithdrawBankAccountTransferApplyUpState', params).then((res)=>{
            if (res.succ) {
              this.showTipMessage("审核成功",'success')
              this.getListData()
            } else {
              this.showTipMessage(res.msg)
            }
            this.showVerify = false
            this.disagreeDialog = false
            this.canEdit = false
        },(res)=>{this.canEdit=false;this.showTipMessage(res.msg||'未知错误','error');})
      }
    }
  }
</script>
