<template>
  <div class="content_box">
    <div class="search_bar">
      <el-button type="success"  size="small" @click="showDetailPage=true;modeType='add';curItem={}">新增</el-button>
      <baseSearch :formItemList="searchItemList" @search="search" :emitSearch="true"></baseSearch>
    </div>
    <div class="main-box">
      <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent" ref="mainTable">
        <template v-slot:operation="actionBtn">
          <el-button type="primary" plain size="small" @click="showDetail(actionBtn.data,'edit')" >编辑</el-button>
          <el-button type="warning"  plain size="small" @click="showDetail(actionBtn.data,'upState')" :disabled="canEdit" v-if="actionBtn.data.state=='1'">禁用</el-button>
          <el-button type="success"  plain size="small" @click="showDetail(actionBtn.data,'upState')" :disabled="canEdit" v-if="actionBtn.data.state=='0'">启用</el-button>
        </template>
      </mainTable>
    </div>
  </div>
  <el-dialog v-model="showDetailPage" :title="modeType=='add'?'新增司机账号':'编辑司机账号'" width="50%" style="padding-bottom:20px;min-width:350px">
    <Vform :formItemList="formItemList"  ref="vform" :setFormInfo="{inline:true,labelWidth:'70px',formRules:dynamicRules}" v-if="showDetailPage" >
      <template v-slot:formButton="formBtn">
        <el-form-item >
          <el-button type="primary"  @click="onSubmit(formBtn.validateForm)" :disabled="canEdit" style="margin-left: 80px">确定提交</el-button>
          <el-button type="danger"  @click="showDetailPage=false" :disabled="canEdit">关闭</el-button>
        </el-form-item>
      </template>
    </Vform>
  </el-dialog>
</template>
<script>

export default {
  data() {
    return {
      baseApiUrl: '/system',
      ARR_stateData: [
        { Id: 0, Name: "禁用" },
        { Id: 1, Name: "启用" }
      ],
      mainData: {
        DataList:[],
        Total:-1,
        labelList: [
          {label:"操作",prop:"operation",slot_name:'operation',type:'slot',width:"140", fixed:'left'},
          { label: "姓名", width: 120, prop: "driverName" },
          { label: "身份证", width: 160, prop: "idCard" },
          { label: "手机号", width: 110,prop: "phone"},
          { label: "司机编码", width: 200, prop: "driverCode" },
          { label: "车牌号", width: 240, prop: "carNumber" },
          { label: "状态", minWidth: 80,callBack:(item)=>{ return ['禁用','启用'][item.state]},getColor:(item)=>{return item.state=='0'?'textdanger':'textsuccess'}}
        ]
      },
      showDetailPage:false,
      modeType:'',
      pageinfo:{
        pg_idx: 1,
        pg_size: 15
      },
      canEdit:false,
      curItem:{},
      rules: {
        phone: [
          { required: true, message: "请输入手机号码", trigger: "manual"},
          {pattern: /^1[3-9]\d{9}$/, message: '格式不正确,请输入11位的手机号码'}
        ],
        driverName: [{ required: true, message: "请输入姓名", trigger: "manual"}],
        idCard: [
           { required: true, message: "请输入身份证", trigger: "manual"},
           {pattern: /^\d{17}[\dX]$/, message: '格式不正确,请输入18位的身份证号'}
        ],
      }
    }
  },
  inject: ['$hasPermission'],
  computed: {
    userData(){
      return this.$store.state.user.userInfo
    },
    formItemList() {
      if(this.modeType=='add'||this.modeType=='edit'){
        return [
          {label:"手机号",type: "input", value: "phone", defaultValue:this.curItem.phone||'', span:1},
          {label:"姓名",type: "input", value: "driverName",defaultValue:this.curItem.driverName||'', span:1},
          {label:"身份证",type: "input", value: "idCard", defaultValue:this.curItem.idCard||'',span:1},
          {label:"车牌号",type: "input", value: "carNumber",defaultValue:this.curItem.carNumber||'',span:1},
        ]
      }
    },
    searchItemList() {
      return [
        {label: "状态", type: "select", value: "state", selectOptions:this.ARR_stateData, multiple:false,clearable:true},
        {type: "input", value: "kw",placeholder:'查询关键字'}
      ];
    },
    dynamicRules() {
      let rules = {};
      for (let item of this.formItemList) {
        if (item.visible!=false) {
          rules[item.value] = this.rules[item.value];
        }
      }
      return rules;
    }
  },
  methods: {
    getListData(){
      this.$http.post(this.baseApiUrl+'/GetDriverPageList', {...this.searchData}).then((res)=>{
        this.mainData.DataList = res.data.rows;
        this.mainData.Total= res.data.total||-1;
      });
    },
    getPageCurrent(val){
      this.pageinfo.pg_idx = val;
      this.getListData();
    },
    search(postData){
      this.pageinfo.pg_idx = 1;
      this.searchData=postData||this.searchData;
      this.getListData();
    },
    //
    showDetail(d,type){
      this.curItem=d;
      this.modeType=type;
      if(this.modeType=='upState'){
        let msg=d.state=='0'?'启用成功!':'禁用成功!'
        let title=d.state=='0'?`确定启用司机【${d.driverName}】吗？`:`确定禁用司机【${d.driverName}】吗？`
        this.showConfirm(title,()=>{
          this.canEdit=true;
          this.$http.post(this.baseApiUrl + '/UpdateDriverState',{id:d.id}).then(()=>{
            this.showTipMessage(msg,'success');
            this.canEdit=false;
            this.search();
          },(res)=>{this.canEdit=false;this.showTipMessage(res.msg||'未知错误','error');});
        })
      }else{
        this.showDetailPage=true;
      }
    },
    // 新增&编辑&修改密码
    async onSubmit(validate) {
      const formData = await validate();let postData={},url='';
      postData={...formData};
      if(this.modeType!='add'){postData.id=this.curItem.id}
      url=this.modeType=='add'?'/DriverAdd':'/DriverEdit';
      this.canEdit=true;
      this.$http.post(this.baseApiUrl + url ,postData).then(()=>{
        this.showTipMessage('操作成功!','success');
        this.canEdit=false;
        this.showDetailPage=false;
        this.search();
      },(res)=>{this.canEdit=false;this.showTipMessage(res.msg||'未知错误','error')});
    },
  }
}
</script>
