<template>
  <div class="content_box">
    <div class="search_bar">
      <el-button type="success"  size="small" @click="toPage(1)">管理图库</el-button>
      <base-search :formItemList="searchItemList" @search="search" :emitSearch="true"></base-search>
    </div>
    <div class="main-box">
      <div class="tableBox">
        <el-table :data="DataList" border stripe  class="table-box" style="height:400px" highlight-current-row size="small" ref="elTable">
          <el-table-column label="驿站banner图管理" >
            <template #default="scope" >
              <div>
                <div style="display: flex;justify-content: space-between;align-items: center">
                  <h3>{{scope.row.StageName}}</h3>
                  <div>
                    <el-button type="success"  size="small" @click="submitItem(scope.row)" v-if="scope.row.bannerImg.length!=0">调整顺序</el-button>
                    <el-button type="primary"  size="small"  @click="toPage(2,scope.row)">设置图片</el-button>
                  </div>
                </div>
                <div v-if="scope.row.bannerImg.length!=0" v-for="(imgItem,imgIndex) in (scope.row.bannerImg||[])" style="width:80px;height:80px;margin:0px 20px 15px 0;padding:5px;border:1px solid #ddd;float: left;border-radius: 5px;">
                  <el-image  :lazy='true' :src="imgItem.url"   @click="showPreviewFun(imgIndex,scope.row.bannerImg)" style="width:70px;height:70px"/>
                </div>
                <p v-else>未设置轮播图</p>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="page-box">
          <el-pagination :current-page="pageinfo.pg_idx" :page-size="pageinfo.pg_size" small background :total="Total" @current-change="getPageCurrent"/>
        </div>
      </div>
    </div>
    <el-dialog v-model="showDetailPage" :title="title" width="980" >
      <mainTable :mainOptions="mainData" ref="mainTable">
        <template v-slot:operation="actionBtn">
          <el-button :type="actionBtn.index==0?'info':'success'"  plain size="small" :disabled="actionBtn.index==0" @click="orderClick(actionBtn.index, actionBtn.data, true)">上移</el-button>
          <el-button :type="actionBtn.index==mainData.DataList.length-1?'info':'warning'"  :disabled="actionBtn.index==mainData.DataList.length-1" plain size="small" @click="orderClick(actionBtn.index, actionBtn.data, false)" >下移</el-button>
        </template>
      </mainTable>
      <template #footer>
          <span class="dialog-footer">
            <el-button type="primary" v-on:click="submitForm(true)" :disabled="canEdit">确定提交</el-button>
            <el-button v-on:click="showDetailPage = false">关闭</el-button>
          </span>
      </template>
    </el-dialog>
    <el-image-viewer v-if="showPreview" @close="showPreview=false" :initial-index="intIndex" :url-list="imgUrl" />
  </div>
</template>
<script>
export default {
  data() {
    return {
      searchData: {},
      showDetailPage: false,
      DataList: [],
      Total: -1,
      imgUrl:[],
      intIndex:0,
      mainData: {
        DataList:[],
        tableHeight:300,
        isSerial:false,
        Total:0,
        labelList: [
          { label:"操作",prop:"operation",slot_name:'operation',type:'slot',width:"130", fixed:'left'},
          { label: "文件", width: 70,callBack:(item)=>{return item.url}, isHtml:true},
          { label: "文件名", minWidth: 190, prop: "name" }
        ]
      },
      title:'',
      url:'',//批量更换url
      curItem:{},
      selectData:{},
      curIndex:0,
      modeType:0,
      ClientChannelInfo:[],
      StageInfo:[],
      SalemenInfo:[],
      canEdit:false,
      showPreview:false,
      pageinfo: {
        pg_idx: 1,
        pg_size: 6
      },
      rules: {
        customerFullName: [{ required: true, message: "请客户名称", trigger: "manual"}],
        phone: [{ required: true, message: "请输入联系方式", trigger: "manual"}],
        address: [{ required: true, message: "请输入联系详细地址", trigger: "manual"}],
      }
    }
  },
  inject: ['$hasPermission'],
  computed: {
    searchItemList() {
      return [
        {label: "驿站：", placeholder:'--全部驿站--',type: "select", value: "stageCode", selectOptions:this.StageInfo, multiple:false, keyValue:['Code','Name'],width:'140'},
      ];
    },
    list() {
      return this.$store.state.tags.list
    }
  },
  mounted(){
    top.__goodsInfoHandler = () => {
      this.getListData();
    }
    this.commonAjax();
  },
  methods: {
    commonAjax() {
     this.$store.dispatch('cache/loadCacheData', {...this.CacheKeys.StageInfo, query:{...this.CacheKeys.StageInfo.query,state: 1}}).then((res) => {this.StageInfo = res;});
    },
    getListData() {
      this.$http.post('/carousel/StageCarouselFigurePage', {...this.searchData, ...this.pageinfo}).then((res) => {
        this.DataList = res.data.rows.map((item) => {item.bannerImg = item.JsonData?item.JsonData:[]; return item;});
        this.Total = res.data.total || -1;
      });
    },
    getPageCurrent(val) {
      this.pageinfo.pg_idx = val;
      this.getListData();
    },
    search(postData) {
      if (postData) this.pageinfo.pg_idx = 1;
      this.searchData = postData || this.searchData;
      this.getListData();
    },
    showPreviewFun(index, d) {
      let list = [];
      d.forEach((item) => {
        list.push(item.url)
      })
      this.showPreview = true;
      this.imgUrl = list;
      this.intIndex = index;
    },
    submitItem(d) {
      this.title = `编辑【${d.StageName}】轮播图顺序`;
      this.mainData.DataList= d.JsonData
      this.showDetailPage=true;
      this.curItem = d;
    },
    orderClick(index,data,direction){
      let pre = direction ? index - 1 : index + 1
      if(pre < 0 || pre >= this.mainData.DataList.length){
        var type=direction ? 'up' : 'dwon';
        if(type=='up'){
          this.showTipMessage("已经是第一个了，不能上移","warning");
        }else if(type=='dwon'){
          this.showTipMessage("已经是最后一个了，不能下移","warning");
        }
        return
      }
      this.mainData.DataList.splice(index,1)
      this.mainData.DataList.splice(pre,0,data)
    },
    submitForm(){
      let postData= {
          stageCode: this.curItem.StageCode,
          jsonData:JSON.stringify(this.mainData.DataList)
      }
      this.canEdit=true;
      this.$http.post('/carousel/UpSequence', postData).then(() => {
        this.showTipMessage('调整banner图顺序成功！','success')
        this.search();
        this.showDetailPage=false;
        this.canEdit=false;
      },(res)=>{this.canEdit=false;this.showTipMessage(res.msg||'未知错误','error');});
    },
    toPage(type,d){
      if(type==1){
        this.$store.dispatch('tags/setTagsItem', {
          name: '/minapp-MerchantInfoManage-Category',
          title: '管理图库',
          path: '/minapp-BannerManage-Gallery',
          type: 'child'
        });
        this.$router.push({path:'/minapp-BannerManage-Gallery'})
      }else{
        this.$store.dispatch('tags/setTagsItem',{
          name: 'minapp-BannerManage-SetImage',
          title: '设置图片',
          path: '/minapp-BannerManage-SetImage?stageCode='+d.StageCode,
          type:'child'
        });
        this.$router.push({path: '/minapp-BannerManage-SetImage', query:{stageCode:d.StageCode}})
      }
    }
  }
}
</script>
