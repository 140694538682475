<template>
  <div class="content_box">
    <div class="search_bar">
      <div>
        <el-button type="success" size="small" @click="exportData()">导出</el-button>
        <el-button type="primary" size="small" @click="createDeliveryOrder()">生成配送单</el-button>
      </div>
      <baseSearch :formItemList="searchItemList" @search="search" :emitSearch="true"></baseSearch>
    </div>
    <div class="main-box">
      <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent" ref="mainTable">
        <template v-slot:operation="actionBtn">
          <el-button :type="actionBtn.data.detailState==0?'info':'primary'" plain :disabled="actionBtn.data.detailState==0" size="small" @click="showDetail(actionBtn.data)" >到货信息</el-button>
          <el-button :type="actionBtn.data.detailState==0?'info':'warning'" plain :disabled="actionBtn.data.detailState==0" size="small" @click="showAbnormal(actionBtn.data)" >异常上报日志</el-button>
        </template>
      </mainTable>
    </div>
  </div>
  <el-dialog v-model="showDetailPage" :title="curOrder.orderNo+'-到货信息'" width="70%" style="padding-bottom:20px;">
    <mainTable :mainOptions="detailData" ref="detailTable"></mainTable>
  </el-dialog>
  <el-dialog v-model="showAbnormalPage" :title="curOrder.orderNo+'-异常上报日志'" width="70%" style="padding-bottom:20px;">
    <mainTable :mainOptions="abnormalData" ref="detailTable"></mainTable>
  </el-dialog>
</template>
<script>
import {formatBatchDate,formatBatch} from '@/utils/common'
export default {
  data() {
    return {
      baseApiUrl: '/collection',
      detailStateList:[
        {Code:0,Name:'末到货'},
        {Code:1,Name:'己到货'},
        {Code:2,Name:'待配送'},
        {Code:3,Name:'配送中'},
        {Code:4,Name:'已入驿站'},
      ],
      dateBegin:this.$formatDate(0, -3),
      dateEnd:this.$formatDate(0,+3),
      mainData: {
        DataList:[],
        isSerial:false,
        isMultiple: true,
        Total:-1,
        labelList: [
          {label:"操作",prop:"operation",slot_name:'operation',type:'slot',width:"200", fixed:'left'},
          { label: "配送日期", width: 140, prop: "deliveryDate" },
          { label: "单号", width: 200, prop: "orderNo" },
          { label: "条目编码", width: 120, prop: "itemSerialCode" },
          { label: "商品名称", width: 220, prop: "productName" },
          { label: "规格", width: 80, prop: "spec" },
          { label: "驿站", width: 140, prop: "warehouseName" },
          { label: "下单数量", width: 80, prop: "unitQty" },
          { label: "单位", width: 60, prop: "unit" },
          { label: "状态", width: 70,callBack:(item)=>{ return item.detailStateName},getColor:(item)=>{return !item.detailState?'textdanger':'textsuccess'} },
          { label: "到货体积(m³)", width: 110, prop: "vol" },
          { label: "到货重量(kg)", width: 110, prop: "weight" },
          { label: "到货数量", width: 70, prop: "arriveQty" },
          { label: "出库数量", width: 70, prop: "outboundQty" },
          { label: "到货时间", width: 140, prop: "arriveTime" },
          { label: "确认人", width: 70, prop: "arriveBy" },
          { label: "运营商", width: 220, prop: "operatorName" },

          // { label: "确认时间", minWidth: 140, prop: "remark" },
        ]
      },
      CommonWarehouse:[],
      showDetailPage:false,// 到货明细
      curOrder:{},
      detailData: {
        DataList:[],
        tableHeight:'400',
        Total:0,
        labelList: [
          { label: "订单号", width: 220, prop: "orderNo" },
          { label: "条目编码", width: 100, prop: "itemSerialCode" },
          { label: "商品名称", minWidth: 180, prop: "productName" },
          { label: "规格", width: 100, prop: "spec" },
          { label: "批次", width: 100, prop: "batch" },
          { label: "到货数量", width: 70, prop: "arriveQty" },
          { label: "销售单位", width: 70, prop: "saleUnit"}
        ]
      },
      showAbnormalPage:false,
      abnormalData: {
        DataList:[],
        tableHeight:'400',
        Total:0,
        labelList: [
          { label: "订单号", width: 210, prop: "orderNo" },
          { label: "商品编码", width: 100, prop: "productCode" },
          { label: "商品名称", minWidth: 200, prop: "productName" },
          { label: "运营商", minWidth: 200, prop: "operatorName" },
          { label: "差异数量", minWidth: 70, prop: "differUnitQty" },
          { label: "差异金额", minWidth: 100, prop: "differAmount" },
          { label: "批次", width: 110, prop: "batch" },
          { label: "差异上报类型", width: 100, prop: "differTypeName" },
          { label: "具体原因", minWidth: 180, prop: "remark" },
          { label: "上报人", width: 100, prop: "createdBy" },
          { label: "上报时间", width: 160, prop: "createdTime" },
        ]
      },
      pageinfo:{
        pg_idx: 1,
        pg_size: 15
      },
      canEdit:false,
      curItem:{},
    }
  },
  inject: ['$hasPermission'],
  computed:{
    searchItemList(){
      return [
        {type: "daterange", value: "createtime",defaultValue:[this.dateBegin,this.dateEnd]},
        {label: "", type: "select", value: "warehouseCode", placeholder:'请选择驿站',selectOptions:this.CommonWarehouse,keyValue:['Code','Name'], multiple:false},
        {label: "", type: "select", value: "detailState", width:160,placeholder:'请选择状态',selectOptions:this.detailStateList,keyValue:['Code','Name'], multiple:true},
        {type: "input", value: "kw",placeholder:'支持单号查询'}
      ]
    }
  },
  mounted() {
    this.getWarehouse();
  },
  methods: {
    getListData(){
      this.$http.post(this.baseApiUrl+'/GetWholesaleCollectionOrderDetailPageList', {...this.searchData,...this.pageinfo}).then((res) => {
        this.mainData.DataList = res.data.rows;
        this.mainData.Total= res.data.total||-1;
      });
    },
    getPageCurrent(val){
      this.pageinfo.pg_idx = val;
      this.getListData();
    },
    search(postData){
      this.pageinfo.pg_idx = 1;
      this.searchData=postData||this.searchData;
      this.getListData();
    },
    getWarehouse(){
      this.$store.dispatch('cache/loadCacheData',this.CacheKeys.CommonWarehouse).then((res) => {
        this.CommonWarehouse = res;
      });
    },
    createDeliveryOrder(){
      let data=this.$refs.mainTable.select();
      if(!data) return;
      let orderNos=[];
      data.map((item)=>{orderNos.push(item.orderNo)})
      this.showConfirm('确定将所勾选的集单生成配送单吗？',()=>{
        this.$http.post(this.baseApiUrl+'/CreateDeliveryOrder',{"orderNos":orderNos.join(',')}).then((res)=>{
          this.showTipMessage("操作成功",'success')
          this.getListData();
        })
      })
    },
    showAbnormal(d){
      this.curOrder=d;
      this.$http.post(this.baseApiUrl+'/GetWholesaleDeliveryQtyDifference', {orderNo:d.orderNo,itemSerialCode:d.itemSerialCode}).then((res) => {
        if(res.data&&res.data.length==0||!res.data){
          this.showTipMessage("该明细无异常上报日志");
          return;
        }
        this.abnormalData.DataList = res.data;
        this.showAbnormalPage = true;
      });
    },
    showDetail(d){
      this.curOrder=d;
      this.$http.post(this.baseApiUrl+'/ArrivalInfo', {id:d.id}).then((res) => {
        this.detailData.DataList = res.data;
        this.showDetailPage = true;
      });
    },
    exportData(){
      this.postExportData({...this.searchData}, this.baseApiUrl+'/GetWholesaleCollectionOrderDetailPageList');
    },
  }
}
</script>
