<template>
 <div class="content_box">
  <div class="search_bar">
    <el-button type="success"  size="small" @click="showDetailPage=true;modeType='add'; this.title='添加小程序公告';curItem={};selectData.stageCode='';visibleStage=false">添加</el-button>
    <baseSearch :formItemList="searchItemList" @search="search" :emitSearch="true"></baseSearch>
  </div>
  <div class="main-box">
    <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent" ref="mainTable">
      <template v-slot:operation="actionBtn">
        <el-button :type="[1,2].indexOf(actionBtn.data.state)!=-1?'info':'primary'"  plain size="small"  @click="submitItem(actionBtn.data,'edit')" :disabled="[1,2].indexOf(actionBtn.data.state)!=-1">编辑</el-button>
        <el-button type="success"  plain size="small" @click="submitItem(actionBtn.data,'ck')" :disabled="canEdit">查看</el-button>
        <el-button  :type="[1,2].indexOf(actionBtn.data.state)!=-1?'info':'warning'" plain size="small" @click="submitItem(actionBtn.data,'audit')" :disabled="[1,2].indexOf(actionBtn.data.state)!=-1">审核</el-button>
        <el-button :type="actionBtn.data.state==2 || actionBtn.data.ifDis == true?'info':'danger'"  plain size="small" @click="submitItem(actionBtn.data,'abolish')" :disabled="actionBtn.data.state==2 || actionBtn.data.ifDis == true">作废</el-button>
      </template>
    </mainTable>
  </div>
  <el-dialog v-model="showDetailPage" :title="title" top="4vh" style="width:80%;max-width:980px;padding-bottom:10px;">
    <div style="color:rgb(184,112,26);background:#FEF1E0;padding:5px 10px;font-size:12px;margin-bottom:10px;">
      <p><el-icon><BellFilled/></el-icon>提示:</p>
      <p>①不选商圈，公告将在所有商圈内生效</p>
      <p>②不选驿站，公告将在指定商圈（所选商圈）的所有驿站生效。</p>
    </div>
    <Vform :formItemList="formItemList"  ref="vform" :setFormInfo="{inline:false,labelWidth:70,formRules:dynamicRules}" @changeform="changeform" v-if="showDetailPage">
      <template v-slot:formButton="formBtn">
        <el-form-item>
          <el-button type="primary"  @click="onSubmit(formBtn.validateForm)" v-if="modeType=='add'||modeType=='edit'">确定提交</el-button>
          <el-button type=""  @click="showDetailPage=false" v-if="modeType=='add'||modeType=='edit'">关闭</el-button>
        </el-form-item>
      </template>
    </Vform>
  </el-dialog>
  </div>
</template>
<script>
import {cloneObject,getMappingName} from '@/utils/common'
export default {
  data() {
    const dateBegin = this.$formatDate(0, -3);
    const dateEnd = this.$formatDate(0,+1);
    const ARR_Type = [
      {Id:0,Name:'通知'},
      {Id:1,Name:'停单'}
    ];
    const ARR_State = [
      {Id:0,Name:'未审核'},
      {Id:1,Name:'已审核'},
      {Id:2,Name:'已作废'}
    ];
    return {
      baseApiUrl: '/announcement',
      searchItemList: [
        {label: "生效时间", type: "daterange", value: "createtime",defaultValue:[dateBegin,dateEnd]},
      ],
      searchData: {},
      mainData: {
        DataList: [],
        Total: -1,
        labelList: [
          { label:"操作",prop:"operation",slot_name:'operation',type:'slot',width:"250", fixed:'left'},

          { label: "开始时间", width: 130, callBack:(item)=>{ return this.$formatDate(item.startTime,'','yyyy-mm-dd hh:MM')}},
          { label: "结束时间", width: 130, callBack:(item)=>{ return this.$formatDate(item.endTime,'','yyyy-mm-dd hh:MM')}},
          { label: "审核时间", width: 140,callBack:(item)=>{return item.auditedTime == '0001-01-01 00:00:00'?'':item.auditedTime;}},
          { label: "商圈", width: 80,callBack:(item)=>{return !item.merchantName?'全部商圈':item.merchantName;}},
          { label: "驿站", minWidth: 100,callBack:(item)=>{return !item.stageName?'全部驿站':item.stageName;}},
          { label: "状态", width: 60,callBack:(item)=>{return getMappingName(item.state,ARR_State)},getColor:(item)=>{return ["textwarning", "textsuccess","textdanger"][item.state]}},
          { label: "公告类型", width: 70,callBack:(item)=>{return getMappingName(item.type,ARR_Type)},getColor:(item)=>{return item.type=='0'?'textsuccess':'textdanger'}},
        ]
      },
      FormItems: [
        {label:"起止时间",type: "datetimerange", value: "createtime",timeFormat:'YYYY-MM-DD HH:mm:ss'},
        {label:"公告类型",type: "select", value: "type",placeholder: '请选择公告类型',selectOptions:ARR_Type,defaultValue:0},
        {label:"商圈",type: "select", value: "merchantCode",placeholder: '请选择商圈',keyValue:['Code','Name'],multiple:true},
        {label:"驿站",type: "select", value: "stageCode",placeholder: '请选择驿站',keyValue:['Code','Name'],multiple:true},
        {label:"公告",type: "textarea", value: "content",placeholder: '请选择公告内容'}
      ],
      StageInfo:'',
      title:'',
      showDetailPage:false,
      visibleStage:false,
      selectData:{},
      curItem:{},
      canEdit:false,
      pageinfo: {
        pg_idx: 1,
        pg_size: 15
      },
      rules: {
        timelist:[{ required: true, message: "请选择起止时间", trigger: "manual" }],
        content: [{ required: true, message: "请输入公告内容", trigger: "manual" }],
      }
    }
  },
  inject: ['$hasPermission'],
  computed: {
    formItemList() {
      return this.FormItems.map(item => ({
        ...item,
        visible:item.value=='stageCode'?this.visibleStage:true,
        selectOptions:this.selectData[item.value]||item.selectOptions||'',
        defaultValue: item.multiple==true&&typeof this.curItem[item.value]=='string'?this.curItem[item.value]&&this.curItem[item.value].split(','):(this.curItem[item.value]??item.defaultValue)
      }));
    },
    dynamicRules() {
      let rules = {};
      for (let item of this.formItemList) {
        if (item.visible!=false) {
          rules[item.value] = this.rules[item.value];
        }
      }
      return rules;
    }
  },
  mounted(){
    this.commonAjax();
  },
  methods: {
    commonAjax(){
      this.$store.dispatch('cache/loadCacheData',{...this.CacheKeys.MerchantInfo,query:{...this.CacheKeys.MerchantInfo.query,state:-1}}).then((res) => {
        this.selectData.merchantCode=res;
      });
      this.$store.dispatch('cache/loadCacheData',{...this.CacheKeys.StageInfo,query:{...this.CacheKeys.StageInfo.query,state:-1}}).then((res) => {
        this.StageInfo=res;
      });
    },
    getListData() {
      this.$http.post(this.baseApiUrl + '/GetMpAnnouncementListPage', {...this.searchData, ...this.pageinfo}).then((res)=>{
        let now = new Date().getTime()
        res.data.rows.forEach(item => {
          if(now>new Date(item.endTime).getTime()){
            item.ifDis = true
          }else{
            item.ifDis = false
          }
        });
        this.mainData.DataList = res.data.rows;
        this.mainData.Total = res.data.total || -1;
      });
    },
    getPageCurrent(val) {
      this.pageinfo.pg_idx = val;
      this.getListData();
    },
    search(postData){
      if(postData) this.pageinfo.pg_idx = 1;
      let Data=postData?cloneObject(postData):'';
      if (Data) {
        Data.startTime = postData.dateFrom;
        Data.endTime = postData.dateTo;
        delete Data.dateFrom;
        delete Data.dateTo;
      }
      this.searchData=Data||this.searchData;
      this.getListData();
    },
    showSelect(d){
      let index=this.hotData.DataList.findIndex(item=>  d.ItemSerialCode==item.ItemSerialCode)
      return index !=-1;
    },
    selectItem(d){
      if(this.hotData.DataList.length>4){
        this.showTipMessage('爆款商品不可超过5个','error');
        return true;
      }
      this.hotData.DataList=[...this.hotData.DataList,d]
    },
    searchDetail(postData){
      let data=this.$refs.vform.formatData();
      if(!data.merchantCode){
        this.canEdit=false;this.showTipMessage('请先选择商圈后选择驿站再搜索','error');
        return;
      }else if(!data.stageCode){
        this.canEdit=false;this.showTipMessage('请先选择驿站再搜索','error');
        return;
      }
      this.$http.post(this.baseApiUrl + '/GetLimitGoods',{stageCode:this.modeType=='edit'?this.curItem.Code:data.stageCode,...postData}).then((res)=>{
        this.channelData.DataList=res.data;
      },(res)=>{this.showTipMessage(res.msg||'未知错误','error');});
    },

    submitItem(d,type){
      let url='',postData={},msg='',title='';
      if(type=='abolish'||type=='audit'){
        url='/MpAnnouncementUpdateState';
        msg=type=='abolish'?'作废成功':'审核成功';
        title=type=='abolish'?'确认作废该公告吗？':'确认审核该公告吗？';
        postData=type=='abolish'?{id:d.id,operationType:2}:{id:d.id,operationType:1};
      }else{
        this.modeType=type;
        this.title=type=='edit'?"编辑小程序公告":"查看小程序公告";
        this.showDetailPage=true;
        let item=cloneObject(d);
        item.createtime=[item.startTime,item.endTime],
        this.curItem=item;
        this.visibleStage =!item.merchantCode?false:true;
        this.changeform(item);
        return;
      }
      this.showConfirm(title,()=>{
        this.canEdit=true;
        this.$http.post(this.baseApiUrl + url,postData).then(()=>{
          this.showTipMessage(msg,'success');
          this.canEdit=false;
          this.search();
        },(res)=>{this.canEdit=false;this.showTipMessage(res.msg||'未知错误','error');});
      })
    },
    changeform(d){
      d.id=this.modeType=='edit'?this.curItem.id:'';
      // d.createtime=this.curItem.createtime||[];
      d.createtime=this.curItem.createtime||[d.dateFrom,d.dateTo];
      this.curItem=d
      if((typeof d.merchantCode=='string'&&!d.merchantCode)||d.merchantCode.length==0||d.merchantCode.length>1){
        this.selectData.stageCode='';
        this.curItem.stageCode=[];
        this.visibleStage = false;
        return;
      }
      if(JSON.stringify(d.merchantCode)!=JSON.stringify(this.curItem.merchantCode)||!this.selectData.stageCode){
        this.$http.post('/Ajax/DCLoad', {t:'StageListByMerchantCode',code:d.merchantCode.join(',')}).then((res)=>{
          this.visibleStage = true;
          this.selectData.stageCode = res.data;
        })
      }
    },
    async onSubmit(validate) {
      const formData = await validate();
      let url=this.modeType=='edit'?'/MpAnnouncementEdit':'/MpAnnouncementAdd';
      let postData= {
        id:this.curItem.id??'',
        merchantCode: !formData.merchantCode?'':formData.merchantCode.join(','),
        stageCode: !formData.stageCode?'':formData.stageCode.join(','),
        startTime:formData.dateFrom,
        endTime:formData.dateTo,
        type:formData.type,
        content:formData.content
      }
      this.canEdit=true;
      this.$http.post(this.baseApiUrl + url ,postData).then(()=>{
        this.showTipMessage('操作成功!','success');
        this.canEdit=false;
        this.showDetailPage=false;
        this.getListData();
      },(res)=>{this.canEdit=false;this.showTipMessage(res.msg||'未知错误','error')});
    },
  }
}
</script>
