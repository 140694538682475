<template>
  <div class="content_box">
    <div class="search_bar">
      <el-button type="primary" v-on:click="exportData" size="small">导出</el-button>
      <baseSearch :formItemList="searchItemList" @search="search" :emitSearch="true"  @changeform="changeform" type="product"></baseSearch>
    </div>
    <div class="main-box">
      <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent" ref="mainTable">
        <template v-slot:operation="actionBtn">
          <el-button type="danger"  plain size="small" @click="submitItem(actionBtn.data)" >下架</el-button>
        </template>
      </mainTable>
    </div>
    <el-dialog v-model="PromotionTypedialog" :title="curItem.itemSerialCode+'【'+curItem.productNamePromotion+'】--参与促销活动概览'" style="width:70%;max-width:880px;padding-bottom: 15px;">
      <div class="main-box">
        <mainTable :mainOptions="PromotionData">
          <template v-slot:operation="actionBtn">
            <el-button size="small" type="primary" @click="getPromotionDetail(actionBtn.data)">查看详情</el-button>
          </template>
        </mainTable>
      </div>
    </el-dialog>
    <el-dialog v-model="PromotionInfodialog" :title="promotionInfo.taskCode+'【'+promotionInfo.taskName+'】'+promotionInfo.promotionTypeName+'详情'" style="width:70%;max-width:880px;padding-bottom: 15px;">
      <el-descriptions :column="2" border size="small">
        <el-descriptions-item label="可销售区域"  span="2" label-class-name="my-label">{{promotionInfo.allowCoverageAreaName}}</el-descriptions-item>
        <el-descriptions-item label="驿站"  span="2" label-class-name="my-label">{{promotionInfo.warehouseName}}</el-descriptions-item>
        <el-descriptions-item label="客户渠道"  label-class-name="my-label">{{promotionInfo.clientChannelName}}</el-descriptions-item>
        <el-descriptions-item label="商圈" label-class-name="my-label">{{promotionInfo.merchantName}}</el-descriptions-item>
      </el-descriptions>
      <h4 class="labelTitle">商品列表</h4>
      <h3 v-if="promotionInfo.promotionType=='BUNDLE_DEAL'">
        组合总价：<span class="textdanger">{{TotalPrice?TotalPrice.toFixed(2)+'/套':'未设置'}}</span>，
        <span>{{bundleLimity==-1?'无限购上限':`限购&nbsp;${bundleLimity}&nbsp;套`}}&nbsp;&nbsp;&nbsp;</span>
      </h3>
      <mainTable :mainOptions="productData"  ref="mainTable"></mainTable>
      <h4 class="labelTitle" v-if="['MULTI_ITEM_GIFT','ONE_ITEM_GIFT'].indexOf(promotionInfo.promotionType)>-1">搭赠列表</h4>
      <mainTable :mainOptions="gifListData" v-if="['MULTI_ITEM_GIFT','ONE_ITEM_GIFT'].indexOf(promotionInfo.promotionType)>-1" ></mainTable>
    </el-dialog>
  </div>
</template>
<script>
import {getMappingName} from '@/utils/common'
export default {
  data() {
    return {
      baseApiUrl: '/promotion',
      ARR_isBundle: [
        { Id: "0", Name: "否" },
        { Id: "1", Name: "是" },
      ],
      ARR_isOnShelf: [
        { Id: "0", Name: "未提审" },
        { Id: "1", Name: "已上架" },
        { Id: "2", Name: "待审核" },
        { Id: "3", Name: "无货" },
        { Id: "4", Name: "作废" },
        { Id: "5", Name: "未上架" },
      ],
      searchData: {},
      showDetailPage: false,
      mainData: {
        DataList: [],
        Total: -1,
        labelList: [
          { label:"操作",prop:"operation",slot_name:'operation',type:'slot',width:"70", fixed:'left'},
          { label: "城批编号", width: 100, prop: "itemSerialCode" },
          { label: "产品名称", width: 190, prop: "productNamePromotion" },
          { label: "运营商名称", width: 250, prop: "operatorName" },
          { label: "组合装", width: 60,  callBack:(item)=>{ return getMappingName(item.isBundle,this.ARR_isBundle)},getColor:(item)=>{return item.isBundle == 1?'textwarning':'textsuccess'}},
          { label: "特价等级", width: 70, callBack:(item)=>{ return item.onSaleLv == 1 ? "优先" : "普通"},getColor:(item)=>{return item.onSaleLv == 1?'textwarning':'textsuccess'}},
          { label: "状态", width: 85, prop: "isOnShelfName" },
          { label: "品类", width: 100, prop: "saasType" },
          { label: "商圈价", width: 85, prop: "promotionPrice" },
          { label: "驿站", width: 180, prop: "stageName" },
          // { label: "驿站价", width: 80, prop: "stageUnitPrice",sortable:true},
          // {label: "参与促销类型", width:"100",callBack:(item)=>{return item.promotionTypeName||'无'},getColor:(item)=>{return  item.promotionTypeName? "textprimaryline" : ""},getClick:(item)=>{item.promotionTypeName&&this.itemClick(item,'PromotionType')}},
          { label: "运费", width: 80, prop: "freightPrice" },
          { label: "运费单位", width: 80, prop: "freightUnit" },
          // { label: "商圈", width: 100, prop: "merchantName" },
          { label: "库存", width: 80, prop: "stockQty", sortable:true},
          { label: "可销售区域", width: 250, prop: "coverageAreaName" },
          { label: "客户渠道", width: 190, prop: "clientChannelName" },
        ]
      },
      PromotionTypedialog:false,
      PromotionInfodialog:false,
      PromotionData: {
        DataList:[],
        isMultiple: false,
        tableHeight: 200,
        labelList: [
          {label: "促销活动编码", prop: "taskCode", minWidth:"180"},
          {label: "促销类型", prop: "promotionTypeName", width:"80"},
          {label: "开始时间", prop: "startTime", width:"160"},
          {label: "结束时间", prop: "endTime", width:"160"},
          {label: "状态", width: 80, callBack:(item)=>{ return item.groupStateName},getColor:(item)=>{return item.groupState==3?'textsuccess':'textdanger'}},
          {label:"操作",prop:"operation",slot_name:'operation',type:'slot',width:"110", fixed:'right', align:"center"},
        ]
      },
      productData: {
        DataList:[],
        tableHeight: 200,
        labelList: [
          {label: "商品名称", prop: "productNamePromotion", minWidth:"220"},
          {label: "状态", width: 80, callBack:(item)=>{ return item.detailStateName},getColor:(item)=>{return item.detailState==1?'textsuccess':'textdanger'}},
          {label: "单价", prop: "unitPrice", width:"100"},
          {label: "限购数量", prop: "limitQty", width:"100",isShow:false},
          {label: "组合比例", prop: "qty", width:"100",isShow:false},
          {label: "运费", prop: "freightPrice", width:"80"},
        ],
      },
      gifListData: {
        DataList:[],
        tableHeight: 140,
        labelList: [
          {label: "搭赠品", prop: "name", minWidth:"220",},
          {label: "单位",  prop: "saleUnit",width:"60"},
          {label: "规则描述", prop: "ruleDesc", width:"340"},
        ],
      },
      TotalPrice:0,
      bundleLimity:-1,
      promotionInfo:{},
      merchantCode:'',
      MerchantInfo:[],
      OperatorInfo:[],
      stageList:[],
      CategoryInfo:[],
      curItem:{},
      pageinfo: {
        pg_idx: 1,
        pg_size: 15
      },
    }
  },
  inject: ['$hasPermission'],
  computed: {
    searchItemList() {
      return [
        {label: "", type: "select", placeholder: '请选择运营商',value: "operatorCode", selectOptions: this.OperatorInfo, multiple: false, keyValue:['Code','Name'],clearable:true},
        {label: "是否组合装", type: "select", value: "isBundle", selectOptions: this.ARR_isBundle, defaultValue:['0','1'], multiple: true,width:'140'},
        // {label: "", type: "select", value: "merchantList", selectOptions:this.MerchantInfo,defaultValue:this.searchData.merchantList||this.MerchantInfo[0]?.Code, multiple: false, keyValue:['Code','Name'],width:'120'},
        // {label: "", placeholder: '请选择驿站', type: "select", value: "warehouseCode", selectOptions:this.stageList, multiple: false, keyValue:['Code','Name'],width:'120',clearable:true},
        // {label: "", type: "select", placeholder: '请选择品类', value: "saasType", selectOptions: this.CategoryInfo, multiple: false, keyValue:['Name','Name'],width:'100',clearable:true},
        //
        // {label: "", type: "select", value: "isOnShelf", selectOptions: this.ARR_isOnShelf,defaultValue:['1'],  multiple: true,width:'160'},
        {type: "input", value: "kw", placeholder: '查询商品名/编码/条码',width:'130'}
      ];
    }
  },
  watch:{
    'searchData.merchantList'(val,oldVal){
      if(val&&oldVal){
        this.getCategoryInfo(val);
        this.getStageInfo(val);
      }
    }
  },
  mounted(){
    this.commonAjax();
  },
  methods: {
    commonAjax(){
      this.$store.dispatch('cache/loadCacheData', {...this.CacheKeys.MerchantInfo, query:{...this.CacheKeys.MerchantInfo.query,state: -1}}).then((res) => {
        this.MerchantInfo=res;
        if(res.length!=0){
          this.merchantCode=res[0].Code;
          this.getCategoryInfo(res[0].Code);
          this.getStageInfo(res[0].Code);
        }
      });
      this.$store.dispatch('cache/loadCacheData',this.CacheKeys.OperatorInfo).then((OperatorInfo) => {
        this.$store.dispatch('cache/loadCacheData', {...this.CacheKeys.StageInfo, query:{...this.CacheKeys.StageInfo.query,state: -1}}).then((StageInfo) => {
          this.OperatorInfo=OperatorInfo.concat(StageInfo)
        });
      });
    },
    getCategoryInfo(code){
      this.$http.post('/Ajax/DCLoad', {t:'CategoryList',code:code}).then((res) => {
        this.CategoryInfo= res.data;
      })
    },
    getStageInfo(code){
      this.$http.post('/Ajax/DCLoad', {t:'StageListByMerchantCode',code:code}).then((res) => {
        this.stageList= res.data;
      })
    },
    getListData() {
      this.$http.post(this.baseApiUrl + '/PromotionProductListV3', {...this.searchData, ...this.pageinfo,isBundle:this.searchData.isBundle?this.searchData.isBundle:'0,1'}).then((res) => {
        this.mainData.DataList = res.data.rows;
        this.mainData.Total = res.data.total || -1;
      });
    },
    getPageCurrent(val) {
      this.pageinfo.pg_idx = val;
      this.getListData();
    },
    changeform(postData){
      this.merchantCode=postData.merchantList;
      this.searchData = postData;
    },
    search(postData) {
      if (postData) this.pageinfo.pg_idx = 1;
      this.searchData = postData || this.searchData;
      this.getListData();
    },
    itemClick(item) {
      this.curItem=item;
      this.$http.post('/promotionTask/GetPromotionTaskListByCode', {itemSerialCode:item.itemSerialCode}).then((res) => {
        this.PromotionData.DataList = res.data;
        this.PromotionTypedialog=true;
      });
    },
    getPromotionDetail(item){
      this.promotionInfo=item;
      this.PromotionInfodialog=true;
      this.TotalPrice=0;
      let index=this.productData.labelList.findIndex(item=>item.prop=='limitQty');
      let qtyIndex= this.productData.labelList.findIndex(item=>item.prop=='qty');
      this.productData.labelList[qtyIndex].isShow=['BUNDLE_DEAL'].indexOf(this.promotionInfo.promotionType)>-1?true:false;
      this.productData.labelList[index].isShow=['SPECIAL_DISCOUNT','SEC_KILL'].indexOf(this.promotionInfo.promotionType)>-1?true:false;
      this.$http.post('/promotionTask/GetPromotionTaskExtAndProductsV3', {taskCode:item.taskCode}).then((res) => {
        this.promotionInfo={...item,...res.data};
        let productData=res?.ext?.product||[];
        if(['MULTI_ITEM_GIFT','ONE_ITEM_GIFT'].indexOf(this.promotionInfo.promotionType)>-1){
          let data=[],list=res?.ext?.gift&&res.ext.gift[0];
          if(list) {
            data = Object.keys(list).map((itemSerialCode) => {
              return {
                itemSerialCode,
                ...list[itemSerialCode]
              };
            });
          }
          this.gifListData.DataList = data;
        }else{
          if(productData.length>0) {
            productData.map((item) => {
              let curGift = (res.ext.gift || []).find(giftItem => item.id == giftItem.id);
              item.limitQty = curGift.rule.limitQty;
              item.qty=curGift.rule.qty;
              this.TotalPrice+=curGift.rule.qty*item.clientPrice;
              this.bundleLimity=curGift.rule.limitQty||-1;
              return item;

            })
          }
        }
        this.productData.DataList =productData;
      });
    },
    submitItem(d){
      this.showConfirm('是否确认下架所选商品？',()=>{
        this.$http.post(this.baseApiUrl + "/PromotionProShelvesV3", {proPromotion:JSON.stringify([{"itemSerialCode":d.itemSerialCode,"isOnShelf":d.isOnShelf}])}).then(() => {
          this.showTipMessage("下架成功！","success");
          this.search();
        });
      })
    },
    exportData(){
      this.postExportData({...this.searchData,...this.pageinfo}, this.baseApiUrl+'/PromotionProductListV3');
    },
  }
}
</script>
<style>
.my-label{width:120px}
</style>
