<template>
  <div class="content_box">
    <div class="search_bar">
      <baseSearch :formItemList="searchItemList" @search="search" :emitSearch="true"></baseSearch>
    </div>
    <div class="main-box">
      <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent" ref="mainTable">
        <template v-slot:operation="actionBtn">
          <el-button type="primary" size="small" @click="showTaskCode=true;curOrder=actionBtn.data" >任务码</el-button>
          <el-button type="danger" size="small" @click="cancelDeliveryOrder(actionBtn.data)" >撤销配送单</el-button>
          <el-button type="success" size="small" @click="showDetail(actionBtn.data)" >查看明细</el-button>
          <el-button type="warning" size="small" @click="exportData(actionBtn.data)" >导出明细</el-button>
        </template>
      </mainTable>
    </div>
  </div>
  <el-dialog v-model="detailDialog" :title="curOrder.deliveryOrderNo+'-配送单明细'" style="width:80%;max-width:980px;padding-bottom:20px">
    <mainTable :mainOptions="detailData"></mainTable>
  </el-dialog>
  <el-dialog v-model="showTaskCode" title="任务码" style="width:500px;padding-bottom:20px">
    <h2>{{curOrder.taskCode}}</h2>
  </el-dialog>
</template>
<script>
import {cloneObject} from '@/utils/common'
export default {
  data() {
    return {
      baseApiUrl: '/collection',
      dateBegin:this.$formatDate(0, -3),
      dateEnd:this.$formatDate(0,+3),
      searchData: {},
      mainData: {
        DataList: [],
        isMultiple:false,
        isSerial:false,
        Total: -1,
        labelList:[
          {label:"操作",prop:"operation",slot_name:'operation',type:'slot',minWidth:"360", fixed:'right'},
          { label: "配送单号", width: 160, prop: "deliveryOrderNo" },
          { label: "配送日期", width: 140, prop: "deliveryDate" },
          { label: "任务明细条数", width: 100, prop: "detailCount" },
          { label: "状态", width: 80,callBack:(item)=>{ return item.orderStateName},getColor:(item)=>{return !item.orderState?'textdanger':'textsuccess'} },
          { label: "配送司机", width: 80, prop: "driverName" },
          { label: "车牌号", width: 120, prop: "carNumber" },
          { label: "总体积(m³)", width: 90, prop: "arriveVol" },
          { label: "总重量(kg)", width: 90, prop: "arriveWeight" },
          { label: "任务接收时间", width: 140, prop: "taskReceiveTime" },
        ]
      },
      CommonWarehouse:[],
      detailDialog:false,
      showTaskCode:false,
      detailData: {
        DataList:[],
        isSerial:false,
        tableHeight: 400,
        labelList: [
          { label: "订单号", width: 220, prop: "orderNo" },
          { label: "条目编码", width: 110, prop: "itemSerialCode" },
          { label: "商品名称", minWidth: 150, prop: "productName" },
          { label: "下单数量", width: 80, prop: "unitQty" },
          { label: "到货数量", width: 80, prop: "arriveQty" },
          { label: "出库数量", width: 70, prop: "outboundQty" },
          { label: "单位", width: 60, prop: "saleUnit" },
          { label: "状态", width: 70, prop: "detailStateName" },
          { label: "规格", width: 80, prop: "spec" },
          { label: "体积(m³)", width: 90, prop: "vol" },
          { label: "重量(kg)", width: 90, prop: "weight" },
        ]
      },
      curOrder:{},
      pageinfo: {
        pg_idx: 1,
        pg_size: 15
      }
    }
  },
  inject: ['$hasPermission'],
  computed:{
    searchItemList(){
      return [
        {type: "daterange", value: "createtime",defaultValue:[this.dateBegin,this.dateEnd]},
        {label: "", type: "select", value: "warehouseCode", placeholder:'请选择驿站',selectOptions:this.CommonWarehouse,keyValue:['Code','Name'], multiple:false},
        {type: "input", value: "kw",placeholder:'支持单号查询'}
      ]
    }
  },
  mounted(){
    this.getWarehouse();
  },
  methods: {
    getWarehouse(){
      this.$store.dispatch('cache/loadCacheData',this.CacheKeys.CommonWarehouse).then((res) => {
        this.CommonWarehouse = res;
      });
    },
    getListData() {
      this.$http.post(this.baseApiUrl + '/GetDeliveryOrderPageList', {...this.searchData, ...this.pageinfo}).then((res)=>{
        this.mainData.DataList = res.data.rows;
        this.mainData.Total = res.data.total || -1;
      });
    },
    getPageCurrent(val) {
      this.pageinfo.pg_idx = val;
      this.getListData();
    },
    search(postData){
      if(postData) this.pageinfo.pg_idx = 1;
      let Data=postData?cloneObject(postData):'';
      if (Data) {
        Data.startTime = postData.dateFrom;
        Data.endTime = postData.dateTo;
        delete Data.dateFrom;
        delete Data.dateTo;
      }
      this.searchData=Data||this.searchData;
      this.getListData();
    },
    cancelDeliveryOrder(item){
      this.showConfirm('确定撤销该配送单吗？',()=>{
        this.$http.post(this.baseApiUrl+'/CancelDeliveryOrder',{"deliveryOrderNo":item.deliveryOrderNo}).then((res)=>{
          this.showTipMessage("操作成功",'success')
          this.getListData();
        })
      })
    },
    showDetail(item){
      this.curOrder=item;
      this.$http.post(this.baseApiUrl+'/DeliveryOrderDetail', {orderNos:item.orderNoArray.join(',')}).then((res) => {
        this.detailData.DataList = res.data;
        this.detailDialog=true;
      });
    },
    exportData(item){
      this.postExportData({orderNos:item.orderNoArray.join(',')}, this.baseApiUrl+'/DeliveryOrderDetail');
    }
  }
}
</script>
